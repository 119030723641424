export default {
  // 公共的key
  common: {
    unrealized: 'ยังไม่สมบูรณ์',
    all: 'ทั้งหมด',
    distributionTip: '0 ดอลลาร์สำหรับค่าส่ง 0',
    easeBack: 'ไม่ต้องห่วง',
    addCarSuccess: 'ประสบความสำเร็จในการเพิ่มรถเข็น',
    deleteConfirm: 'คุณแน่ใจ หรือว่า ต้องการลบรายการ ที่เลือกไว้?'
  },
  //商品详情
  goodsDetail: {
    goodsDetail: 'รายละเอียดสินค้า',
    shopping: 'กำลังเข้าไป',
    end: 'สิ้นสุดระยะทาง',
    sold: 'ขายแล้ว',
    goodsTips: 'รายการนี้ มีมูลค่า 500 กรัม/รายการ ที่มีมูลค่าต่ำกว่า 500 กรัม และราคา ที่แตกต่างกัน',
    quickTime: 'เร็ว ที่สุด ที่จะไปถึงใน 29 นาที',
    specifications: 'รายละเอียด',
    preservationConditions: 'บันทึกเงื่อนไข',
    coldStorage: 'แช่เย็น',
    shelfLife: 'วันหมดอายุ',
    day: '15 วัน',
    addToCar: 'เข้าร่วมซื้อรถเข็น'
  },
  //购物车
  car: {
    title: 'รถเข็น',
    delete: 'ลบ',
    tip: 'รถเข็น ที่ว่า งเปล่า~',
    walk: 'ไปปาร์ตี้กัน',
    all: 'การเลือก',
    guess: 'เดาว่า คุณคงชอบ'
  },
  // 首页
  home: {
    HallofFame: 'ยูสเซอร์ผู้โชคดี',
    chooseLocation: 'โปรดเลือกตำแหน่ง..',
    more: 'เพิ่มเติม',
    home: 'หน้าแรก',
    category: 'การเรียงลำดับ',
    eat: 'กินอะไร?',
    cart: 'รถเข็น',
    mine: 'ของฉัน',
    inputGoodsName: 'ป้อนชื่อรายการ',
    vipDesc: 'การเข้าร่วมสมาชิกคาดว่า จะประหยัดประมาณ 806 ดอลลาร์ต่อปี',
    vipSubDesc: 'ลด 50 %>',
    specialZone: 'พื้น ที่พิเศษ',
    foodie: 'วันกินของ',
    dinner: 'อาหารค่ำ',
    popularity: 'คะแนนนิยม',
    goodChoose: 'การเลือก',
    limitBuy: 'ถูก จำกัด',
    bottomTip: 'ฉันมีขีด จำกัด',
    chooseAddress: 'โปรดเลือก ที่อยู่',
    currentLocation: 'ตำแหน่งปัจจุบันของคุณ',
    nearbyAddress: 'ที่อยู่ใกล้ ๆ'
  },
  // 分类
  category: {
    tip: 'ป้อนชื่อรายการ',
    bottomTip: 'เอาล่ะมาดูประเภทอื่นกัน'
  },
  // 吃什么
  eat: {
    all: 'ทั้งหมด',
    close: 'ลาก่อน',
    pleaseHolder: 'ค้นหาสูตรอาหารส่วนผสม',
    menu: 'เมนูวันนี้',
    menuDevide: 'การเรียงลำดับเมนู',
    recent: 'เพิ่งหามา',
    tip_bottom: 'มันจบแล้วหยุดดึงได้แล้ว!',
    tip: 'ยังไม่มีการจัดเก็บคอลเลกชัน~'
  },
  // 我的
  mine: {
    title: 'ของฉัน',
    phone: 'หมายเลขโทรศัพท์',
    login: 'ล็อกอินทันที',
    myOrder: 'คำสั่งของฉัน',
    allOrder: 'ดูรายการ ที่สั่งทั้งหมด',
    myBill: 'คูปองของฉัน',
    myLocation: 'ที่อยู่การรับของฉัน',
    myCar: 'กรีนการ์ดของฉัน',
    servier: 'ติดต่อฝ่ายบริการลูกค้า',
    servierTime: 'เวลาการบริการลูกค้า 07.00-22 : 00 น.',
    feedback: 'ความคิดเห็น',
    switchLanguage: 'ภาษา',
    chinese: 'ภาษาจีน',
    english: 'ภาษาอังกฤษ',
    version: 'รุ่นปัจจุบัน',
    waitingPay: 'ค่าใช้จ่ายในภายหลัง',
    waitingReceive: 'กำลังรับของ',
    waitingComment: 'ที่จะให้คะแนน',
    waitingFeedback: 'หลังจากการขาย/คืนเงิน',
    unrealized: 'หน้าคืนเงินยังไม่เปิดเลย!',
    tip2: 'อย่าลืมสั่งดาวดวงเล็ก ๆ นะคะ❤️~',
    tip3: '💘ขอบคุณสำหรับความสนใจ💘',
    tip4: 'ค้นหาในคลับ \nGeek-James/ddBuy \n🦉ยินดีต้อนรับสู่ข้อเสนอการปรับ แต่ง🙉',
    feedbackTip: 'ตอนนี้ ยังไม่มีการสั่งซื้อ',
    cardPrise: 'ราคากรีนการ์ด',
    moreMessage: 'ยินดี ที่ได้รู้จักค่ะ...',
    openCard: 'เปิดกรีนการ์ด',
    recomend: 'กรีนการ์ดทั้งหมดอยู่ ที่ 6.66 ดอลลาร์สหรัฐ',
    cardTip1: 'บัตรประจำตัว',
    cardTip2: 'พิเศษเฉพาะ',
    cardTip3: 'อินทิกรัลสองเท่า',
    cardTip4: 'สิทธิพิเศษ',
    vipType: 'ลด 50 %',
    vipTime: 'ปีค.ศ. 365 วัน',
    vipRecommend: 'เท่ากับ 0.24 ดอลลาร์ต่อวัน',
    prise1: '¥88',
    prise2: '¥188',
    vipType1: 'ลด 70 %',
    vipTime1: 'การ์ดซีซั่น 90 วัน',
    vipRecommend1: 'ประมาณ 0.33 ดอลลาร์ต่อวัน',
    prise11: '¥30',
    prise12: '¥45',
    payMethod: 'วิธีการจ่ายเงิน',
    wechatPay: 'การชำระเงินผ่านทางจดหมาย',
    aliPay: 'และเงิน ที่จ่ายไป',
    huabeiPay: 'ก็จ่ายไปสิ',
    payImmeatally: 'จ่ายทันที',
    cardUeless: 'กรีนการ์ดยังไม่เปิด',
    tip: 'นั่นคือ ทั้งหมด ที่! ข้าทำไม่ได้หรอก~~',
    changeNickName: 'แก้ไขชื่อเล่น',
    confirm: 'บันทึก',
    nickName: 'โปรดตั้งชื่อเล่นใหม่',
    personal: 'แก้ไขข้อมูลส่วนบุคคลสำเร็จ!',
    message: 'ชื่อเล่นครับ',
    myBills: 'คูปองของฉัน',
    persent: 'กรุณากดเลข ที่ 520...',
    bill: 'คูปอง',
    condition: 'ไม่มีระดับการใช้งาน\nส่วนลดพิเศษ 1.5 ดอลลาร์',
    rmb: 'หยวน',
    myOrders: 'คำสั่งของฉัน',
    itemsTitle: ['ทั้งหมด', 'ค่าใช้จ่ายในภายหลัง', 'กำลังรับของ', 'ที่จะให้คะแนน'],

    personalInfo: 'ข้อมูลส่วนบุคคล',
    head: 'หัว',
    user_name: 'ชื่อเล่น',
    userSex: 'เพศ',
    Brithday: 'วันเกิด',
    phoneNumber: 'หมายเลขโทรศัพท์',
    noInput: 'ไม่ต้องกรอก',
    logout: 'ออกจากการล็อกอิน',
    infoTip: 'แก้ไขข้อมูลส่วนบุคคลสำเร็จ',
    loginInfo: 'แน่ใจนะว่า จะออกจากระบบ?',
    infoSuccess: 'ถอนตัว!',
    year: 'ปี',
    month: 'เดือน',
    day: 'วัน',
    man: 'รูปหล่อ',
    woman: 'คนสวย',

    greenCard: 'กรีนการ์ด',
    greenCardPower: 'สิทธิ์ของกรีนการ์ด',
    fiftypecert: 'ลด 50 % สำหรับกรีนการ์ด',
    cardRecoamnd: 'กรีนการ์ดจะได้คูปองส่วนลดทุกวัน',
    TodayExclusivecoupon: 'วันนี้ เอาไปให้หมดเลย',
    everyUpadate: 'ปรับปรุงทุกวันตอน 0 โมง',
    to: 'เติมเต็ม',
    toUse: 'ตั้งค่าโครงการ',
    immeatallyGet: 'รับทันที',
    weekBill: 'อาทิตย์นี้ จะได้คะแนน',
    cardTip7: 'รับทันที',
    billTip: 'กรีนการ์ดจะได้คูปองส่วนลดทุกวัน',
    currentGoods: 'การช้อปปิ้งปัจจุบันของคุณ',
    cardShop: 'เปิดกรีนการ์ดช้อปปิ้ง',
    cardBack: 'อินทิกรัลย้อนกลับเป็น',
    cardSpecialTip: 'เริ่มอินทิกรัลได้ในทันที',
    cardSpecial: 'กรีนการ์ดพิเศษพิเศษ',
    cardPriseb: '180 ดอลลาร์',
    cardPrisea: '88 ดอลลาร์',
    yearCard: 'การ์ดปี',
    openCards: 'เปิดกรีนการ์ด',
    onece: 'หนึ่งครั้ง',
    twice: 'สองเท่า'
  },
  // 订单
  order: {
    outTimeGetMoney: 'ได้รับการชดเชยมากกว่า 10 นาที',
    inputForm: 'เติมคำสั่ง',
    location: 'เลือก ที่อยู่ ที่จะรับ',
    arrivalTime: 'ถึงเวลา',
    total: 'เอาเลย',
    thing: 'บางอย่าง',
    wechatPay: 'การชำระเงินผ่านทางจดหมาย',
    aliPay: 'และเงิน ที่จ่ายไป',
    huabeiPay: 'ก็จ่ายไปสิ',
    use: 'ใช้',
    order12: 'อินทิกรัล',
    mark: 'หมายเหตุ',
    tip: 'การกรอกข้อมูลจะบอกเราได้ว่า คุณต้องการอะไรเป็นพิเศษ',
    goodsList: 'รายการสินค้า',

    totalMoney: 'จำนวนรายการ',

    sendMoney: 'ค่าจัดส่ง',
    point: 'อินทิกรัล',
    order19: 'จ่ายจริง',

    pay: 'จ่ายจริง',
    sendForm: 'ส่งคำสั่ง',

    selectArrivalTime: 'เลือกเวลาส่งมอบ',
    comfirm: 'แน่ใจ',
    goods: 'รายการ',
    all: 'เอาเลย',
    things: 'บางอย่าง',
    sigalPrice: 'ราคาเท่านั้น:',
    numbers: 'จำนวน:',
    addTip: 'ยังไม่มี ที่อยู่ให้เพิ่มเลยค่ะ😄',

    addLocation: 'เพิ่ม ที่อยู่',
    comfirmAndUse: 'บันทึก และใช้งาน',
    editLocation: 'แก้ไข ที่อยู่',
    myAddress: 'ที่อยู่ของฉัน',
    settlement: 'ตกลง',
    chooseGoods: 'กรุณาเลือกรายการ ที่ต้องการคำนวณ',
    deliveryTime: 'โปรดเลือกเวลาสำหรับการส่งมอบ',
    today: 'วันนี้',
    tomorrow: 'พรุ่งนี้'
  },
  Login: {
    indexTitle: 'ล็อกอิน',
    Daftar: {
      title: 'ล็อกอิน',
      phone: 'กรุณาใส่เบอร์มือถือของคุณ',
      code: 'โปรดป้อนรหัสการตรวจสอบ',
      password: 'โปรดป้อนรหัสผ่าน',
      againPassword: 'โปรดป้อนรหัสผ่านของคุณอีกครั้ง',
      remenber: 'จดจำรหัสผ่าน'
    },
    Forgot: {
      sendCode: 'ส่งแล้ว',
      confirm: 'ลงทะเบียน',
      goBack: 'ย้อนกลับ'
    },
    nameInput: 'กรุณาใส่ชื่อลงทะเทียน',
    pwdInput: 'โปรดป้อนรหัสผ่าน',
    remenber: 'จดจำรหัสผ่าน',
    loginButton: 'เข้าสู่ระบบ',
    registrasi: 'ลงทะเบียน'
  },
  // 登录
  login: {
    title: 'ล็อกอิน',
    phoneNumber: 'หมายเลขโทรศัพท์',
    phoneNumberNotCorrect: 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง',
    phoneNumberNotEmpty: 'หมายเลขโทรศัพท์ไม่ว่า ง',
    passNumberNotEmpty: 'รหัสผ่านไม่สามารถว่า งได้',
    phoneInput: 'กรุณาพิมพ์หมายเลขโทรศัพท์ของคุณ',
    pass: 'รหัสผ่าน',
    passTip: 'โปรดป้อนรหัสผ่าน',
    varify: 'โปรดป้อนรหัสการตรวจสอบ',
    otherMethods: 'วิธีการล็อกอินอื่น ๆ',
    wechat: 'เข้าสู่ระบบอ่านจดหมาย',
    qqchant: 'คิวคิวเข้าสู่ระบบ',
    tipTile: 'คำแนะนำ ที่ดี：',
    tipContent: 'หมายเลขโทรศัพท์ ที่ไม่ได้ลงทะเบียนจะลงทะเบียนอัตโนมัติเมื่อเข้าสู่ระบบด้วยตัวแทนยินยอม',
    tip: 'โปรโตคอลผู้ใช้',
    tipProcy: 'กลยุทธ์ความเป็นส่วนตัว',
    login: 'ล็อกอิน',
    resgin: 'ลงทะเบียน',
    passTip2: 'โปรดป้อนรหัสผ่าน (ไม่น้อยกว่า 6 บิต)',
    sendVerify: 'ส่งรหัสการตรวจสอบ',
    hasSend: 'ส่งแล้ว',
    phoneVerify: 'หมายเลขบัญชีเข้าสู่ระบบ',
    smsMessage: 'เข้ารหัสการตรวจสอบข้อความ',
    switchLoginMsg: 'หมายเลขบัญชีเข้าสู่ระบบ',
    messageSuccess: 'ป้อนรหัสผ่านสำเร็จโปรดป้อนในกล่องใส่ข้อมูล',
    pleaseInputCorrectPhoneNumber: 'กรุณาใส่เลขโทรศัพท์ ที่ถูกต้อง',
    pleaseInputCorrectVerifyumber: 'โปรดป้อนรหัสการตรวจสอบ ที่ถูกต้อง',
    otherWechat: 'ล็อคอินเข้าสู่ระบบจดหมาย - ยังไม่เสร็จสมบูรณ์',
    otherQQ: 'คิว ที่ล็อกอิน - ยังไม่เสร็จสมบูรณ์',
    message: 'ตรวจสอบรหัสผ่าน:'
  },
  Hint: {
    notMore: 'ไม่มีอีกแล้ว',
    loading: 'กำลังโหลด',
    phoneNot: 'หมายเลขโทรศัพท์ หรือหมายเลขบัญชีไม่ว่า ง',
    SendCodeSuccess: 'ส่งรหัสยืนยันเรียบร้อยแล้ว!',
    pwdInconformity: 'สองรหัสผ่านไม่สอดคล้องกัน',
    notInput: 'ป้อนข้อมูล ที่ยังไม่สมบูรณ์',
    failed: 'ล้มเหลว'
  },
  newTab: {
    home: 'หน้าแรก',
    money: 'เติมเงิน',
    order: 'คำสั่ง',
    my: 'ของฉัน',
  },
  tab: {
    make_money: 'กำลังรับ'
  },
  Cart: {
    zzInfo: {
      buyIng: 'ในการซื้อ',
      buyFailed: 'การซื้อล้มเหลว',
      notLevel: 'คุณขาดคะแนนสมาชิก',
      buyJurisdiction1: 'หากจำเป็นต้องซื้อคำสั่งซื้อทองคำสำหรับสมาชิก',
      buyJurisdiction2: 'หากจําเป็นต้องซื้อคําสั่งแพลตตินัมของสมาชิก',
      buyJurisdiction3: 'หากจำเป็นต้องซื้อเพชร ที่สั่งซื้อ',
      invite1: 'เชิญเพื่อน 3 คนมาทำเงินด้วยกัน',
      invite4: 'เชิญเพื่อน 10 คนมาทำเงินด้วยกัน',
      invite3: 'เชิญเพื่อน 30 คน และทำเงิน',
      invite2: 'เชิญเพื่อน ๆ',
      myMoney: 'ยอดเงินในบัญชีของคุณ',
      under: 'ต่ำกว่า',
      notSendMoney: 'ไม่สามารถต่อรองได้',
      goTopUp: 'กรุณาดำเนินการกำลังเสริม',
      know: 'เข้าใจแล้ว',
      account: 'ตกลง'
    },
    hint: {
      p1: '1. ระดับสมาชิก ที่แตกต่างกันมีการสั่งซื้อ ที่แตกต่างกัน',
      p2: '2. ระดับสมาชิกสูงขึ้นค่านายหน้าสูงขึ้น',
      p3: '3. สำหรับการสั่งซื้อแล้วคุณสามารถตรวจสอบสถานะการสั่งซื้อได้ตลอดเวลา'
    },
    state: {
      all: 'ทั้งหมด',
      going: 'ดำเนินการอยู่',
      paid: 'จ่ายไปแล้ว',
      frozen: 'มันนิ่งแล้ว',
      over: 'เรียบร้อยแล้ว',
      canceled: 'ยกเลิก'
    },
    info: {
      allMoney: 'ทรัพย์สินรวมของผม ฿',
      dayAllOrder: 'รายการทั้งหมดของวันนี้',
      dayTrustMoney: 'วันนี้ มอบหมายให้คิม',
      yesterdayMoney: 'รายได้ของเมื่อวาน'
    },
    text: {
      one: 'คำสั่ง'
    }
  },
  My: {
    download: 'ดาวน์โหลดAPP',
    personal: 'ข้อมูลส่วนตัว',
    recharge: 'บันทึกการเติมเงิน',
    withdrawal: 'ถอนเงิน',
    language: 'ตั้งค่าภาษา',
    logout: 'ออก',
    MemberInformation: {
      balance: 'ยอดคงเหลือของบัญชี',
      Withdraw: 'ถอนเงิน',
      Recharge: 'เติมเงิน',
      forzenAll: 'หยุดทั้งหมด'
    },
    PersonalInformation: {
      Page: {
        Avatar: 'หัว',
        Nickname: 'ชื่อเล่น',
        PhoneNumber: 'หมายเลขโทรศัพท์',
        BankAccounts: 'บัตรธนาคาร',
        Password: 'รหัสผ่าน',
        title: 'ข้อมูลส่วนตัว',
        levelProgress: "ความคืบหน้าระดับ"
      },
      portrait: {
        SetAvatar: 'ตั้งค่าหัว',
        button: 'แก้ไข'
      },
      Name: {
        ModifyNickname: 'แก้ไขชื่อเล่น',
        title: 'แก้ไขชื่อเล่น',
        name: 'ชื่อเล่น',
        Please: 'กรุณาใส่ชื่อเล่น ที่คุณต้องการแก้ไข'
      },
      Password: {
        title: 'แก้ไขรหัสล็อกอิน',
        PleaseEnter: 'โปรดป้อนรหัสผ่าน ที่คุณต้องการแก้ไข',
        phone: 'โทรศัพท์มือถือ',
        NewPhone: 'กรุณาใส่เบอร์มือถือของคุณ',
        verificationCode: 'ตรวจสอบรหัส',
        verificationCodeNote: 'โปรดป้อนรหัสการตรวจสอบ',
        ConfirmPassword: 'รหัสผ่าน',
        ConfirmPasswordNote: 'โปรดป้อนข้อมูลเชิงลึก ที่คุณต้องการแก้ไข',
        Text: 'โปรดจำรหัสผ่านหากคุณลืมรหัสผ่านโปรดติดต่อฝ่ายบริการลูกค้า。',
        Button: 'ยืนยันการแก้ไข'
      },
      AccountData: {
        title: 'รายละเอียดการซื้อขาย'
      }
    }
  },
  newAdd: {
    all: 'ทั้งหมด',
    Completed: 'เรียบร้อยแล้ว',
    unfinished: 'ยังไม่เสร็จ',
    Income: 'รายได้',
    Expenditure: 'ค่าใช้จ่าย',
    GetCodeBtn: 'กำลังรับรหัสยืนยัน',
    copySuccess: 'การคัดลอกสำเร็จ',
    copyError: 'การคัดลอกล้มเหลว',
    noLoginUserName: 'เข้าสู่ระบบ',
    Withdraw: 'ถอนเงิน',
    Recharge: 'เติมเงิน',
    commission: 'ค่านายหน้า',
    single: 'คำสั่ง',
    missionHall: 'หอปฏิบัติการ',
    videoTutorial: 'บทเรียนวิดีโอ',
    helpCenter: 'ศูนย์ช่วยเหลือ',
    inviteFriends: 'เชิญเพื่อน ๆ',
    help1Title: 'วิธีการรับค่านายหน้าผ่านซีจี',
    help1Context: 'เจ้าของธุรกิจไฟฟ้าจำเป็นต้องเพิ่มยอดขายของร้านดังนั้น เรา\
    เพียงแค่ต้องทำตามคำขอของร้านค้าในซีจีเพื่อให้เสร็จสิ้นการสั่งซื้อเจ้าของจะให้ค่านายหน้าแ\
    ก่เราผู้ใช้ แต่ละคนจะได้รับ 10 ภารกิจต่อวันคุณจะได้ค่านายหน้า 10 % ถึง 30 เปอร์เซ็นต์ภายในหนึ่งวัน',
    help2Title: 'วิธีการเพิ่มจำนวนงาน',
    help2Context: 'เนื่องจากมีงานพาร์ทไทม์มากเกินไป บริษัท จึงกำหนดให้พนักงานทำ\
    งานพาร์ทไทม์ทุกคน ที่ทำงานอินเทอร์เน็ตใช้เพียงคนเดียวในการติดต่อ และรับงาน',
    help3Title: 'จะทำให้งานสำเร็จได้อย่างไร',
    help3Context: 'ช่วยเพิ่มยอดขาย และให้รางวัลตามปริมาณการซื้อขายสำหรับ แต่ล\
    ะคำสั่ง ที่ประสบความสำเร็จคุณได้ค่านายหน้า และผู้ขายได้รับชื่อเสียง',
    referralLink: 'แนะนำลิงก์',
    copy: 'คัดลอก',
    blance: 'ยอดคงเหลือ',
    todayOrder: 'คำสั่งซื้อวันนี้',
    provided: 'ข้อมูลนี้ ได้รับการจัดหา',
    Transaction: 'ตกลง',
    Frozen: 'หยุด',
    Done: 'เรียบร้อยแล้ว',
    Cancel: 'ยกเลิก',
    ExpectedReturn: 'คาดว่า จะกลับ:',
    ImmediatePayment: 'จ่ายทันที',
    AvailableNum: 'จำนวนครั้ง ที่ใช้',
    OrderImmediately: 'ไปยังโฟลเดอร์ทันที',
    MakeMoneyEveryDay: 'รายได้ของวัน',
    TodayRecord: 'คำอธิบายคำสั่งซื้อ',
    AllocatCommissions: 'การกระจายค่าคอมมิชชั่น',
    FrozenAmount: 'จำนวนเงิน ที่ค้างไว้',
    TaskMatch: 'สุ่มเลือกงาน',
    AvailableBalance: 'ยอดเงิน ที่มีอยู่',
    WaitDispatch: 'กรุณาติดต่อผู้จัดการร้านเพื่อรอการมอบหมายงาน!',
    OrderDescription: 'คำอธิบายคำสั่งซื้อ',
    CouldMatch: 'งานจะถูกสุ่มจับคู่โดยระบบ:',
    OrderPerDay: 'คำสั่ง。',
    DescriptionRate: 'รวมค่าคอมมิชชั่นเดียวเป็นจำนวนเงิน ที่สั่งซื้อ',
    DescriptionPeriod: '。',
    Description3: 'ระบบมีพื้นฐานมาจากเทคโนโลยีไอบีเอส และเข้าคู่กับสินค้าโดยอัตโนมัติผ่านเมฆ',
    Description4: 'เพื่อหลีกเลี่ยงไม่ให้ถูกควบคุม หากคำสั่งซื้อไม่ได้รับการยืนยันและจัดส่งภายใน 20 นาที คำสั่งซื้อจะถูกระงับ',
    Note: 'หมายเหตุ:',
    DescriptionNote: 'เมื่อแพลตฟอร์มตรงกับผู้ใช้ในคำสั่งซื้อ แลตฟอร์มจะส่งข้อมูลคำสั่งซื้อไปยังแบ็กเอนด์ของผู้ขาย หากผู้ใช้ไม่ส่งคำสั่งซื้อภายใน 20 นาที จำนวนการสั่งซื้อจะถูกระงับโดยระบบ บัญชีผู้ใช้ที่เสร็จสิ้นภารกิจสามารถรับค่าคอมมิชชั่น สิ่งนี้ส่งผลโดยตรงต่อเวลางานถัดไปของผู้ใช้และอัตราส่วนคอมมิชชัน! กรุณาชำระเงินและถอนให้เสร็จสิ้นภายในเวลาหลังจากได้รับคำสั่งงาน!',
    OrderNum: 'หมายเลขคำสั่ง:',
    TotalOrderPrice: 'ราคารวม:',
    SubmitNow: 'ส่งทันที',
    Record: 'การบันทึก',
    MemberAccount: 'บัญชีสมาชิก',
    AccountPlaceholder: 'กรุณากรอกบัญชีของคุณ',
    RechargeAmount: 'โปรดระบบธนาคารของคุณ',
    RechargeAmountPlaceholder: 'โปรดป้อนจำนวนเงิน',
    SelectRechargeMethod: 'เลือกรูปแบบการเติมเงิน',
    NotBeenSentOrder: 'ยังไม่ได้ส่งคำสั่งซื้อโปรดติดต่อพนักงาน และส่งรายการ ที่สั่ง!',
    TodayOrderOver: 'สั่งเรียบร้อยแล้วค่ะพรุ่งนี้ ลองใหม่นะคะ',
    NotLogin: 'คุณยังไม่ได้ล็อกอินโปรดลองอีกครั้งหลังจากล็อกอิน!',
    HaveNoteMethod: 'ไม่มีวิธีชาร์จแบบนี้ ในตอนนี้ โปรดเลือกวิธีการชาร์จอื่น ๆ',
    Login: 'ล็อกอิน',
    AccountRecord: 'การเปลี่ยนแปลงของบัญชี',
    RealName: 'ชื่อจริง',
    IFSCCODE: 'ไอเอสซี',
    BankName: 'ชื่อบัตรธนาคาร',
    BankNum: 'เลขบัตรธนาคาร',
    Mobile: 'มือถือ',
    MobileNum: 'โทรศัพท์มือถือ',
    Email: 'กล่องจดหมาย',
    EmailRequired: 'กล่องจดหมายจะถูกเติม',
    AddBankCardNote: 'บัตรธนาคารเป็นข้อมูลสำคัญกรุณากรอกอย่างระมัดระวัง!',
    // Submit: 'เข้าสู่ระบบ',
    Submit: 'ตกลง',
    AddBankCardTitle: 'เพิ่มบัตรธนาคาร',
    BankCard: 'บัตรธนาคาร',
    Name: 'ชื่อ?',
    CardNum: 'เลขบัตร',
    Reading: 'กำลังอ่านอยู่...',
    AllBalance: 'ยอดเงินรวม',
    EnterWithdrawalAmount: 'โปรดป้อนจำนวนรวม',
    AllOut: 'ทั้งหมด',
    AskNow: 'ถอนเงิน',
    EnterPwdPlaceholder: 'โปรดป้อนรหัสผ่าน',
    NotHaveCard: 'คุณยังไม่ได้ใช้บัตรเงินคุณต้องการใช้บัตรธนาคาร หรือเปล่า',
    GetBankInfoFail: 'การดึงข้อมูลธนาคารล้มเหลว',
    EnterValidPayPwd: 'โปรดป้อนรหัสผ่านการชำระเงิน ที่ถูกต้อง',
    Balance: 'ยอดคงเหลือ',
    Total: 'ทั้งหมด',
    total: 'ทั้งหมด',
    records: 'การบันทึก',
    DisplayPerPage: 'รูปสี่เหลี่ยมต่อหน้า',
    NullNum: 'แถบ',
    pages: 'หน้า。',
    toAudit: 'หลังจากพิจารณาคดี',
    succeed: 'สำเร็จ',
    RechargeRecordTitle: 'บันทึกการเติมเงิน',
    rests: 'อื่น ๆ',
    Arrive: 'กำลังไป',
    forzenCommission: 'หยุดค่านายหน้า',
    companyIntro: 'รายละเอียด บริษัท',
    companyTitle: 'ทาตามอลล์เป็นส่วนหนึ่งของทาทากรุ๊ป',
    warmTitle: 'คำแนะนำ ที่ดี',
    companyText: 'กลุ่มทาทาเป็นกลุ่มธุรกิจ ที่ใหญ่ ที่สุดในอินเดียก่อตั้งขึ้นในปีค.ศ. 1868 ในมุมไบอินเดีย การดำเนินงานทางธุรกิจ ที่เกี่ยวข้องกับทั้งเจ็ดสาขาเทคโนโลยีการสื่อสาร และข้อมูลวิศวกรรมการบริการพลังงานสินค้าอุปโภคบริโภค และผลิตภัณฑ์เคมี ในฐานะกลุ่มธุรกิจ ที่กำลังพัฒนาอย่างรวดเร็วของอินเดียกลุ่มทาทามี บริษัท มากกว่า 200 บริษัท ในกว่า 80 ประเทศในหกทวีป รายได้รวมในปีงบประมาณ 2021 เท่ากับ 100 ล้านดอลลาร์สหรัฐ และ 58 % นั้น มาจากธุรกิจต่างประเทศ กลุ่มมีพนักงานประมาณ 450,000 คนทั่วโลก ตั้ง แต่ปี 153 ชื่อทาตาได้รับความเคารพอย่างมากในอินเดียเพราะมันยึดมั่นในคุณค่า ที่ดี และศีลธรรมทางธุรกิจ อันดับ ที่ 11 ของ บริษัท ที่ได้รับความนิยมมาก ที่สุดในโลกจากฟอร์บส์',
    depositAddress: 'ที่อยู่เติมเงิน',
    rechargeSteps: 'ขั้นตอนการเติมเงิน',
    completePayment: 'การเติมเต็มเสร็จสมบูรณ์',
    rechargeNow: 'เติมเงินทันที',
    rechargeCancel: 'ยกเลิกการเติม',
    bankName: 'บัญชีธนาคาร',
    payee: 'ชื่อบัญชี',
    cardNum: 'ธนาคาร',
    reviewing: 'ในการตรวจสอบ',
    remittance: 'มีการโอนเงินแล้ว',
    notThrough: 'ล้มเหลว',
    OrderID: 'คำสั่งID',
    "Amount": "จำนวน",
    "toCutScreen": "โปรดถ่ายภาพหน้าจอหากภาพไม่พร้อมใช้งาน!",
    "cardTabBank": "ธนาคาร",
    "cardTabUSDT": "USDT",
    "name": "ชื่อ",
    "chainType": "ประเภทโซ่",
  },
  "mine": {
    "servier": "บริการ"
  },
  "add": {
    "Commission": "คณะกรรมการ",
    "Yesterday": "เมื่อวาน",
    "Cumulative": "สะสม",
    "Today": "วันนี้",
    "TeamNum": " ขนาดทีมทั้งหมด",
    "TeamMoney": " คณะกรรมการทีมทั้งหมด",
    "Personalcenteraccountchangerecord": "บันทึกการเปลี่ยนแปลงบัญชี",
    "lockedbalance": "สมดุลล็อค",
    "Cumulativeinvitation": "คำเชิญสะสม",
    "Validinvitation": "คำเชิญที่ถูกต้อง",
    "Accumulatedrebate": "เงินคืนสะสม"
  },
  "Merchant": "รายการ",
  "all": "ทั้งหมด",
  "btn.recharge": "เติมเงิน",
  "btn.withdraw": "ถอน",
  "btn.service": "บริการ",
  "merchant.all": "ทั้งหมด",
  "merchant.long": "เวลานาน",
  "merchant.short": "ระยะเวลาอันสั้น",
  "merchant.hot": "หวยยอดนิยม",
  "merchant.list": "ประเภทหวย",
  "tab.merchants": "รายการ",
  "orderDeadlineText": "กำหนดเวลาการเดิมพัน",
  "orderResultText": "ผลการจับคู่",
  "status.waiting": "ที่จะวาด",
  "status.done": "ได้รับการวาด",
  "status.fail": "ข้อผิดพลาดลอตเตอรี",
  "status.open": "เปิด ...",
  "open.big": "สูง",
  "open.small": "ต่ำ",
  "open.single": "คี่",
  "open.double": "คู่",
  "大小单双": "คู่เล็กขนาดเล็กสองเท่า",
  "大单": "ซิงเกิลใหญ่",
  "大双": "คู่ใหญ่",
  "小单": "เดี่ยวขนาดเล็ก",
  "小双": "คู่เล็ก ๆ",
  "btn.clear": "ยกเลิกทั้งหมด",
  "btn.match": "เดิมพัน",
  "hint.moneyLess": "สมดุลไม่เพียงพอ!",
  "hint.opening": "ไม่อนุญาตให้สั่งซื้อในระหว่างการปิด!",
  "hint.matchSuccess": "จับคู่ได้สำเร็จ!",
  "hint.matchFail": "การแข่งขันล้มเหลว!",
  "popup.title.buyConfirm": "ยืนยันรายการ",
  "popup.text.data": "ข้อมูล",
  "yuan": "หยวน",
  "popup.title.matchAmount": "จำนวนเงินที่ตรงกับ",
  "popup.title.matchContent": "จับคู่เนื้อหา",
  "hint.confirmBuy": "กรุณายืนยันคำสั่งซื้อภายในระยะเวลาที่กำหนด",
  "popup.btn.match": "เดิมพัน",
  "hint.toAddOrder": "กรุณาเลือกหมายเลขสั่งซื้อ!",
  "hint.toPerPrice": "กรุณากรอกจำนวนการสั่งซื้อ!",
  "popup.title.matchResult": "จับคู่ผล",
  "popup.orderId": "หมายเลขสั่งซื้อ",
  "tab.activity": "กิจกรรม",
  "toDetails": "ตรวจสอบรายละเอียด",
  "activity.title": "พื้นที่กิจกรรม",
  "activity.time": "เวลาทำกิจกรรม",
  "nav.activityDetails": "รายละเอียดกิจกรรม",
  "order.status.all": "ทั้งหมด",
  "order.status.waiting": "ที่จะวาด",
  "order.status.done": "เสร็จแล้ว",
  "order.typeTitle": "พิมพ์",
  "order.totalPrice": "จำนวนเงินเดิมพัน",
  "order.winPrice": "รายได้",
  "order.status": "สถานะ",
  "nav.orderDetails": "รายละเอียดสั่งซื้อ",
  "orderDetails.title.time": "เวลาเดิมพัน",
  "orderDetails.title.type": "รายการ",
  "orderDetails.title.expect": "ปัญหา",
  "orderDetails.title.method": "วิธี",
  "orderDetails.title.result": "ผลลัพธ์",
  "orderDetails.title.inType": "ซื้อ",
  "orderDetails.title.odds": "การทำให้เสียเงิน",
  "orderDetails.title.inPerPrice": "ราคาซื้อ",
  "orderDetails.title.inNum": "ซื้อปริมาณ",
  "orderDetails.title.winAmount": "รายได้",
  "game.perPrice.text": "จำนวนเงิน",
  "balance.text": "ยอดเงินคงเหลือ",
  "input.permoney.placeholder": "ใส่จำนวนเงิน",
  "user.tools.introduce": "แนะนำ",
  "user.tools.crypto": "crypto",
  "user.tools.ba": "การระเบิด",
  "page.walletList": "รายการกระเป๋าเงิน",
  "addWallet": "เพิ่ม",
  "walletTypeBank": "ธนาคาร",
  "walletTypeUSDT": "USDT",
  "btn.editWallet": "แก้ไข",
  "agent": {
    "title": {
      "memberControl": "การจัดการสมาชิก",
      "share": "แชร์ลิงก์อ้างอิง",
      "agentRead": "นโยบายหน่วยงาน",
      "betInfo": "ข้อมูลการเดิมพัน",
      "paymentInfo": "ข้อมูลการชำระเงิน",
      "teamInfo": "รายงานของทีม",
      "daily": "รายวัน",
      "agent": "ตัวแทน",
      "agentCommission": "คณะกรรมาธิการหน่วยงาน"
    }
  },
  "My.financial": "บันทึกรายการ",
  "page.financial": "บันทึกรายการ",
  "page.financialRecord": "บันทึกทางการเงิน",
  "hint.InternetErr": "ข้อผิดพลาดเครือข่ายโปรดลองอีกครั้งในภายหลัง!",
  "totalDeposit": "เงินฝากทั้งหมด",
  "totalRevenue": "รายได้รวม",
  "btn.transferInto": "การฝาก",
  "btn.transferOut": "การถอน",
  "page.transferInto": "การฝาก",
  "page.transferOut": "การถอน",
  "balanceTransfer": "การโอนสมดุล",
  "availableBalance": "ยอดเงินคงเหลือ",
  "depositRate": "อัตราเงินฝาก",
  "days": "วัน",
  "hint.success": "ความสำเร็จ",
  "hint.fail": "ล้มเหลว",
  "status.into": "การฝาก",
  "status.out": "การถอน",
  "label.amount": "จำนวน",
  "label.time": "เวลา",
  "my.vip": "สิทธิพิเศษวีไอพี",
  "my.verifyPhone": "ตรวจสอบโทรศัพท์",
  "my.modifyPass": "เปลี่ยนรหัสผ่าน",
  "my.wallet": "กระเป๋าสตางค์",
  "my.betRecord": "บันทึกการเดิมพัน",
  "page": {
    "member": "สมาชิก",
    "teamReport": "รายงานของทีม",
    "agentReport": "รายงานตัวแทน",
    "agentCommission": "คณะกรรมาธิการตัวแทน",
    "verifyPhone": "การยืนยันทางโทรศัพท์",
    "withdrawRecord": "บันทึกการถอนเงิน",
    "games": "เกม",
    "openRecord": "บันทึกเปิด"
  },
  "placeholder": {
    "member": "ค้นหาสมาชิก"
  },
  "btn": {
    "search": "ค้นหา",
    "sort": {
      "level": "ระดับ",
      "indate": "ทำให้ไม่พอใจ"
    },
    "confirm": "ยืนยัน",
    "cancel": "ยกเลิก"
  },
  "member": {
    "title": {
      "level": "ระดับ"
    },
    "label": {
      "indate": "ทำให้ไม่พอใจ",
      "loginData": "ลงชื่อเข้าใช้เวลา",
      "superior": "ดีกว่า",
      "member": "สมาชิก"
    },
    "notLogin": "ไม่เข้าสู่ระบบ",
    "toDetails": "ดูรายละเอียด"
  },
  "memberDetails": {
    "tabs": {
      "teamInfo": "สถิติของทีม",
      "lower": "ระดับต่ำ"
    },
    "lowerMember": "สมาชิกจูเนียร์",
    "teamBalance": "ยอดคงเหลือของทีม",
    "ownBalance": "ยอดเงินส่วนบุคคล",
    "personalRecord": "บันทึกส่วนตัว",
    "teamRecord": "บันทึกทีม",
    "btn": {
      "today": "วันนี้",
      "yesterday": "เมื่อวาน",
      "thisMonth": "เดือนนี้",
      "lastMonth": "เดือนที่แล้ว"
    },
    "table": {
      "personalBalance": "ยอดคงเหลือของ {ชื่อผู้ใช้}",
      "childrenNum": "จำนวนสมาชิกทั้งหมดของ {ชื่อผู้ใช้}",
      "rechargeAmount": "จำนวนเงิน {ชื่อผู้ใช้} ฝาก",
      "withdrawAmount": "จำนวนเงินถอน {ชื่อผู้ใช้}",
      "betAmount": "จำนวนเงิน {ชื่อผู้ใช้} เดิมพัน",
      "winAmount": "จำนวนเงิน {ชื่อผู้ใช้} ชนะ",
      "groupBalance": "ดุลกลุ่ม",
      "teamProfit": "ผลกำไรกลุ่ม",
      "groupRegNum": "การลงทะเบียนทีม",
      "groupBetUsers": "จำนวนทีมนักพนัน",
      "groupBetAmount": "จำนวนเงินเดิมพันทีม",
      "groupWinNum": "จำนวนผู้ด้อยโอกาสที่ชนะ",
      "groupWinAmount": "ชนะจำนวนที่ต่ำกว่า",
      "groupRechargeAmount": "จำนวนการเติมเงินของทีม",
      "groupWithdrawAmount": "จำนวนเงินถอนทีม",
      "betCommission": "คณะกรรมการการเดิมพัน",
      "groupBetCommission": "คณะกรรมการการเดิมพันทีม"
    }
  },
  "teamReport": {
    "from": "จาก",
    "to": "ถึง",
    "quickSelect": "การเลือกอย่างรวดเร็ว",
    "groupBalance": "ยอดคงเหลือปัจจุบันของกลุ่ม",
    "label": {
      "membersNum": "จำนวนสมาชิกทั้งหมด",
      "rechargeAmount": "เงินฝากทั้งหมด",
      "withdrawAmount": "ถอนทั้งหมด",
      "betNum": "จำนวนสมาชิกเดิมพัน",
      "betAmount": "เดิมพันทั้งหมด",
      "winNum": "จำนวนสมาชิกที่ชนะ",
      "winAmount": "ชนะทั้งหมด",
      "commission": "คณะกรรมการ"
    }
  },
  "agentReport": {
    "searchPlaceholder": "ชื่อตัวแทนค้นหา ...",
    "label": {
      "total": "ทั้งหมด",
      "recharge": "เติม",
      "withdraw": "ถอน",
      "bet": "เดิมพันทั้งหมด",
      "commission": "คณะกรรมการ",
      "admin": "การปรับผู้ดูแลระบบ",
      "win": "ชนะ",
      "promotion": "การส่งเสริม",
      "profit": "กำไร"
    },
    "formula": "(*) กำไร = รวม WOMMOMH + โบนัส + ค่าคอมมิชชั่น"
  },
  "phoneVerify": {
    "label": {
      "code": "รหัสการตรวจสอบ"
    },
    "placeholder": {
      "code": "โปรดป้อนรหัสการยืนยัน"
    },
    "btn": {
      "send": "ส่ง",
      "confirm": "ตรวจสอบ"
    }
  },
  "game": {
    "rule": {
      "title": "คำแนะนำ",
      "close": "ปิด"
    }
  },
  "open.result": "ผลลัพธ์",
  "open.hz": "ผลรวม",
  "open.openTime": "ใช้เวลา",
  "login.rememberPassword": "จำรหัสผ่าน",
  "successWithdrawof": "การถอนเงินที่ประสบความสำเร็จ",
  "hint.usernameEnter": "กรุณากรอกชื่อผู้ใช้!",
  "hint.passwordEnter": "กรุณาใส่รหัสผ่านของคุณ!",
  "hint.passwordAgainEnter": "โปรดยืนยันรหัสผ่านของคุณ!",
  "hint.formidEnter": "กรุณากรอกรหัสเชิญ!",
  "hint.registeRead": "ฉันอายุมากกว่า 18 ปีและยอมรับข้อกำหนดและเงื่อนไขของเว็บไซต์นี้",
  "hint.read": "โปรดตรวจสอบให้แน่ใจว่าได้อ่านข้อตกลงและลองอีกครั้ง!",
  "My.PersonalInformation.Password.oldPass": "กรุณากรอกรหัสผ่านเก่า",
  "mine": {
    "label": {
      "rechargeRecord": "บันทึกเติมเงิน"
    }
  },
  "recharge.enter": "เข้า",
  "rechargeAccountEnter": "กรุณากรอกชื่อบัญชีธนาคารของผู้โอน",
  "game.typeHz": "ผลรวม",
  "game": {
    "fiveBall": {
      "0": "ลูกแรก",
      "1": "ลูกสอง",
      "2": "ลูกที่สาม",
      "3": "ลูกที่สี่",
      "4": "ลูกที่ห้า"
    }
  },
  "Home.HallofFame": "หอเกียรติยศ",
  "newAdd.ChainType": "ประเภทโซ่",
  "newAdd.WalletAddress": "ที่อยู่",
  "withdraw.currency.label": "สกุลเงินเสมือน",
  "withdraw.label.enterAmount": "จำนวนเงินที่ถอนออก",
  "Login.Daftar.referrer": "รหัสเชิญ(ID)",
  "My.quit": "ล้มเลิก",
  "My.logoutEvent": "ยืนยันการออก?",
  "My.Button.One": "ยกเลิก",
  "My.Button.Two": "ยืนยัน",
  Financing: {
    "time": "เวลา",
    "day": "วัน",
    "Fina": "บันทึกรายการ",
    "assets": "สินทรัพย์รวม",
    "amount": "จำนวนเงิน",
    "revenue": "รายได้รวม",
    "earnings": "รายได้ของเมื่อวานนี้",
    "Balance": "การโอนสมดุล",
    "RP": " ",
    "Estimated": "รายได้โดยประมาณ",
    "Income": "มาตรฐานรายได้",
    "TransferOut": "การถอน",
    "ransferIn": "โอนเข้า",
    "SukuBung": "Suku Bunga",
    "Transfer": "โอนในความล้มเหลว",
    "Pleaseint": "กรุณากรอกจำนวนเงิน",
    "Successful": "การถ่ายโอนที่ประสบความสำเร็จ",
    "Please": "กรุณาป้อนการถ่ายโอนในจำนวนเงิน Silakan Masukkan โอน Dalam Jumlah",
    "RansferInSU": {
      "FinancialAcc": "บันทึกการเข้าถึงทางการเงิน",
      "Acc": "บันทึกการเข้าถึง"
    },
    "ServiceSu": {
      "Please": "กรุณากรอก ...",
      "send": "ส่งออกไป",
      "Online": "บริการออนไลน์",
      "Service": "บริการ",
      "Customer": "Pelayanan Pelanggan",
      "Ifyou": "หากคุณพบปัญหาและต้องการความช่วยเหลือโปรดติดต่อออนไลน์ Cunstomer Servic",
      "Layanana8": "ศูนย์บริการลูกค้าหมายเลข 8 (WhatsApp)",
      "Layanana9": "ศูนย์บริการลูกค้าหมายเลข 9 (WhatsApp)",
      "Dedicated": "บริการ Cunstomer เฉพาะ",
      "Telegram8": "Layanana Pelanggan No.8 (โทรเลข)",
      "Telegram008": "Telegram008",
      "Telegram9": "Layanana Pelanggan No.9 (โทรเลข)",
      "Telegram009": "Telegram009"
    }
  },
  "game": {
    "broadcast": {
      "title": "กำไรออกอากาศแบบเรียลไทม์",
      "th_tradeUser": "ยูสเซอร์ผู้ใช้งาน",
      "th_tradeType": "วิธีการเดิมพัน",
      "th_winAmount": "จำนวนกำไร"
    },
    "chooseGameTitle": "เลือกเล่นเกม"
  },
  "blindbox": {
    "remainTimes": "จำนวนการวาด: {__} ครั้ง",
    "submit": "ลอตเตอรีตอนนี้",
    "rule": "กฎหมายลอตเตอรี",
    "service": "บริการออนไลน์",
    "navigator": "หวย",
    "noticeText": "ขอแสดงความยินดีกับลูกค้า {__} ที่ได้รับรางวัล {___}"
  },
  "Dialog.success.title": "ขอแสดงความยินดีค่ะ!",
  "Dialog.error.title": "ขออภัยที่ไม่ได้รับรางวัล!",
  "Dialog.success.message1": "การเดิมพันล่าสุดคือ:",
  "Dialog.success.message2": "จำนวนเงินที่ได้รับคือ:",
  "Dialog.error.message": "จำนวนเงินที่สูญเปล่าคือ:"
}