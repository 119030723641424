<template>
  <div
    id="app"
    :style="{
      '--theme': verConfig.theme,
      '--navTheme': verConfig.navTheme,
      '--bg': verConfig.pagesBG,
      '--grey': '#b8ecff',
    }"
  >
    <router-view />
    <WelfareDialog
      v-model="welfareDialogShow"
      :content="welfareDialogContent"
      :msgId="messageInfo.id"
      @read="read"
      :next="nextmsg"
      :clickOverlay="false"
      :backgroundImage="require('@tt/assets/home/dialog_bg.png')"
    />
  </div>
</template>
<script>
import { Tabbar, TabbarItem, Toast } from "vant";
import WelfareDialog from "@tt/components/src/welfareDialog.vue";
import { GET_UNREAD_MESSAGES } from "@tt/api";
export default {
  data() {
    return {
      welfareDialogShow: false,
      welfareDialogContent: "",
      messageInfo: {},
      msgList: [],
      nextmsg: false,
    };
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    WelfareDialog
  },
  created() {
    if(!localStorage.getItem("token")){
      if (this.verConfig.notLoginToRegist) {
        return this.$router.replace("/registrasi");
      }
      this.$router.replace("/login")
    }
    // if (!localStorage.getItem('user-info')) {
    //   if (this.$router.history.current.name !== 'login') {
    //     // this.$router.replace('login')
    //     this.$router.push('/login')
    //   }
    // }
    sessionStorage.setItem("version-dialog-show", true);
    if (!localStorage.getItem("version")) {
      localStorage.setItem("version", "1.0.7");
    }
  },
  computed: {
    ifShowTabbar() {
      let list = ["goods", "financing", "cart", "record", "user"];
      console.log(list.includes(this.$router.history.current.name));
      return list.includes(this.$router.history.current.name);
    },
  },
  methods: {
    onChange() {
      // window.console.log(123);
    },
    goto(path = ``) {
      this.$router.push(`/${path}`);
    },
    read(next = false, nextParams = {}) {
      if (nextParams && Object.keys(nextParams).length)
        this.messageInfo = nextParams;
      // this.msgList.shift();
      if (!!this.msgList[0].nextmsg && !next) {
        this.welfareDialogContent = this.msgList[0].nextmsg;
        if (!next) {
          this.nextmsg = true;
        } else {
          this.nextmsg = false;
        }
        this.welfareDialogShow = true;
      }
      // if (this.msgList.length) {
      //   this.welfareDialogContent = this.msgList[0].content;
      //   this.messageInfo = this.msgList[0];
      //   this.welfareDialogShow = true;
      // }
    },
    async getMessage() {
      Toast.loading({duration: 0});
      try {
        const res = await GET_UNREAD_MESSAGES({
          perPage: 999,
          notify: 1,
          notify: 1
        });
        Toast.clear();
        const { ret, msg, data } = res;
        if (!data.total) return;
        if (!!data.lists.length) {
          this.welfareDialogShow = true;
          if (!!this.messageInfo.id && this.messageInfo.id == data.lists[0].id)
            return;
          this.welfareDialogContent = data.lists[0].content;
          this.messageInfo = data.lists[0];
          this.msgList = data.lists;
        }
      } catch (error) {
        console.log(error);
        Toast.clear();
      }
    },
  },
  watch: {
    $route(to, from) {
      const token = localStorage.getItem("token");
      if (
        (to.path === "/registrasi") ||
        (to.path === "/login") ||
        !token
      )
        return;
      this.getMessage();
    },
  },
};

window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>
<style>
@import url("./assets/iconfonts/iconfont.css");
#app {
  width: 100%;
  height: 100%;
}
body {
  font-size: 16px;
  /* background-color: #fff; */
  background: var(--bg);
  -webkit-font-smoothing: antialiased;
}

a {
  position: fixed;
  top: -1000px;
}
</style>
